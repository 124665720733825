// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-cv-jsx": () => import("./../../../src/pages/cv.jsx" /* webpackChunkName: "component---src-pages-cv-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-projects-breathing-life-into-pharmaceutical-research-reports-jsx": () => import("./../../../src/pages/projects/breathing-life-into-pharmaceutical-research-reports.jsx" /* webpackChunkName: "component---src-pages-projects-breathing-life-into-pharmaceutical-research-reports-jsx" */),
  "component---src-pages-projects-giving-redburns-clients-control-of-their-research-content-jsx": () => import("./../../../src/pages/projects/giving-redburns-clients-control-of-their-research-content.jsx" /* webpackChunkName: "component---src-pages-projects-giving-redburns-clients-control-of-their-research-content-jsx" */),
  "component---src-pages-projects-helping-artists-with-limited-free-time-quickly-find-inspiration-jsx": () => import("./../../../src/pages/projects/helping-artists-with-limited-free-time-quickly-find-inspiration.jsx" /* webpackChunkName: "component---src-pages-projects-helping-artists-with-limited-free-time-quickly-find-inspiration-jsx" */),
  "component---src-pages-projects-redesigning-the-homepage-for-redburns-research-website-jsx": () => import("./../../../src/pages/projects/redesigning-the-homepage-for-redburns-research-website.jsx" /* webpackChunkName: "component---src-pages-projects-redesigning-the-homepage-for-redburns-research-website-jsx" */)
}

